import React, { FC } from 'react';
import { MobileHeader } from './MobileHeader';
import { DesktopHeader } from './DesktopHeader';

type HeaderProps = {
  homepage?: boolean;
  transparent?: boolean;
  minimal?: boolean;
};

export const Header: FC<HeaderProps> = ({
  homepage = false,
  transparent = false,
  minimal = false,
}) => {
  return (
    <>
      <MobileHeader transparent={transparent} />
      <DesktopHeader homepage={homepage} transparent={transparent} minimal={minimal} />
    </>
  );
};
